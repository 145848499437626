<style lang="less" scoped>
  @import "../../../assets/css/variables";

  .el-sel-width {
    width: 100%;
  }

  .list-table {
    .dvcimg {
      width: 100px;
      /*height: 100px;*/
    }
    .productImg {
      width: 95px;
      height: 120px;
      padding: 0 10px;
      border: 1px solid #efefef;
      margin: 10px auto;
      text-align: center;
      line-height: 120px;
      color: #999;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      .img {
        width: 75px;
        height: 120px;
        margin: 0 auto;
        color: #bbb;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
  }
</style>

<template :is="view" transition="fade" transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/device/list' }">
        设备管理
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/device/list' }">
        产品型号列表
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-container clearfix" v-on:keyup.13="getDeviceByName">
      <el-col :span="24" style="padding-bottom: 0px;">
        <el-form :inline="true" :model="filters">
          <el-form-item>
            <el-input
              style="width: 364px"
              v-model="filters.key"
              placeholder="请输入设备型号名称"
            ></el-input>
          </el-form-item>
          <el-form-item v-show="false">
            <el-input style="width: 200px"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getDeviceByName">搜索</el-button>
          </el-form-item>
        </el-form>
      </el-col>

      <el-table
        :data="dvcTypeList"
        v-loading.body="listLoading"
        class="list-table"
      >
        <el-table-column type="selection" width="35"></el-table-column>
        <!-- <el-table-column label="产品图片" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.images" class="productImg">
                <div class="img" v-lazy:background-image="API_ROOT+scope.row.images"></div>
            </div>
            <div v-if="!scope.row.images" class="productImg">暂无图片</div>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="name"
          min-width="100"
          :show-overflow-tooltip="true"
          label="型号名称"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          min-width="100"
          label="系列编码"
        >
          <template slot-scope="scope">
            {{ scope.row.deviceTypeCode }}
          </template>
        </el-table-column>
        <el-table-column
          prop="productTypeCode"
          :show-overflow-tooltip="true"
          min-width="100"
          label="产品标识"
        ></el-table-column>
        <el-table-column
          prop="remarks"
          :show-overflow-tooltip="true"
          min-width="120"
          label="备注"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          min-width="120"
          :show-overflow-tooltip="true"
          :formatter="formatTime"
          label="创建时间"
        ></el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button
              @click="handleEdit('edit', scope.$index, scope.row)"
              :disabled="!editPermission"
              size="small"
            >
              修改
            </el-button>

            <el-button
              :disabled="!editPermission"
              type="danger"
              size="small"
              @click="handleDel(scope.$index, scope.row)"
              class="m-l-xs"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--工具条-->
      <el-col :span="24" class="bottom-tool-container">
        <el-button
          :disabled="!editPermission"
          type="primary"
          @click="handleEdit('add')"
        >
          新增类型
        </el-button>
        <!--   <el-button type="danger" @click="batchRemove">批量删除</el-button> -->

        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pager.page"
          :page-sizes="[PAGE_SIZE, PAGE_SIZE * 2, PAGE_SIZE * 3, 100]"
          :page-size="pager.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          class="pull-right"
        ></el-pagination>
      </el-col>
    </div>

    <el-dialog
      size="small"
      :title="(isEdit ? '修改' : '新增') + '产品型号'"
      :visible.sync="dialogShow"
    >
      <device-detail
        :visible="dialogShow"
        :propRowData="choosedDevice"
        @submit="submitEdit"
        @cancel="dialogShow = false"
      ></device-detail>
    </el-dialog>
  </div>
</template>

<script>
  import { getFileUrl } from "../../../utils/tools/tools";
  import { uploadImageFile } from "../../../services/common";
  import { API_ROOT } from "../../../config";
  import { updateDefaultDevice } from "../../../services/ucenter";
  import { getAllCustomers } from "../../../services/customer";
  import { Notification } from "element-ui";
  import { PAGE_SIZE } from "../../../config";
  import DeviceDetail from "./detail.vue";
  import { mapGetters, mapActions } from "vuex";
  import {
    searchDevicesByType,
    updateDvcType,
    createDvcType,
    delDvcType,
    enabledDevice,
    dataStream,
    querySurplusRules,
  } from "../../../services/device";
  import moment from "moment";
  let tempKey = null;

  export default {
    components: {
      "device-detail": DeviceDetail,
    },
    beforeRouteEnter(to, from, next) {
      if (from.query.searchKey) {
        tempKey = from.query.searchKey;
      } else {
        tempKey = null;
      }
      next();
    },
    data() {
      return {
        API_ROOT,
        PAGE_SIZE,
        loading: false, //图片上传用
        editPermission: true,
        dialogShow: false,
        listLoading: false,
        isEdit: false,
        loaded: false,
        isLargeScreen: window.innerWidth > 1400,
        pager: {
          size: PAGE_SIZE,
          page: 1,
          sort: "updateTime,desc",
        },
        total: 1,
        choosedRowIndex: -1, //当前选中的列，主要是在删除和修改的时候用
        filters: {
          key: "",
          isProxy: "",
        },
        //下面是对话框中的信息
        deviceInfo: {
          name: "",
          deviceTypeCode: "",
          productTypeCode: "",
          remarks: "",
          sensors: [],
          status: [],
          surplus: [],
          images: "",
        },
        choosedDevice: {},
        sels: [], //列表选中列
        dvcTypeList: [],
        customerList: [], //品牌商列表
        imageList: [],
        sensorsList: [], //数据类型列表
        statusList: [],
        surplusList: [],
      };
    },
    computed: {
      ...mapGetters({ user: "userInfo" }),
    },
    methods: {
      // 新增编辑成功
      submitEdit() {
        this.dvcTypeList.splice(this.choosedRowIndex, 1, this.choosedDevice);
        this.dialogShow = false;
      },

      /**
       * 设备编辑权限判断
       */
      checkEditPermission() {
        let permissions = this.user && this.user.permissions;
        this.editPermission = permissions.some((item) => {
          return item === "Device:update";
        });
      },
      formatTime(row) {
        return row && row.createTime
          ? moment(row.createTime).format("YYYY-MM-DD HH:mm:ss")
          : "";
      },

      /**
       * 单条数据的删除
       */
      handleDel: function(index, row) {
        this.$confirm("确认删除该记录吗?", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            delDvcType(row.id).then((res) => {
              this.listLoading = false;
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "删除成功",
                });
                this.queryDevices();
              }
            });
          })
          .catch(() => {});
      },
      /**
       * 跳转到编辑
       */
      handleEdit(type, index, row) {
        if (type == "edit") {
          this.choosedRowIndex = index;
          this.choosedDevice = row || {};
        } else {
          this.choosedDevice = {};
        }
        this.dialogShow = true;
      },

      /**
       * 定义序号的排序
       */
      sortDeviceByTime({ column, prop, order }) {
        if (prop === "index") {
          if (order === "ascending") {
            this.pager.sort = "updateTime,asc";
          } else {
            this.pager.sort = "updateTime,desc";
          }
          this.queryDevices();
        }
      },

      /**
       * checkbox勾选的变动的回调
       */
      selsChange: function(sels) {
        this.sels = sels;
      },

      /**
       * 批量删除
       */
      // batchRemove(){
      //   const ids = this.sels.map(item => item.id).toString();
      //   this.$confirm('确认删除选中记录吗？', '提示', {
      //     type: 'warning'
      //   }).then(() => {
      //     this.listLoading = true;
      //     //NProgress.start();
      //     delDvcType(ids).then((res) => {
      //       this.listLoading = false;
      //       //NProgress.done();
      //       if (res.errorCode === 0) {
      //         Notification.success({
      //           title: '成功',
      //           message: '批量删除成功'
      //         });
      //         this.queryDevices();
      //       }
      //     });
      //   }).catch(() => {
      //   });
      // },
      /**
       * 根据设备名筛选
       */
      getDeviceByName() {
        this.queryDevices();
      },

      /**
       *  翻页
       */
      handleCurrentChange(page) {
        //        this.pager.page = page;
        //        this.queryDevices();
        if (this.loaded) {
          this.pager.page = page;
          window.router.replace({ path: "kind", query: { page: page } });
          this.queryDevices();
        }
      },
      /**
       * 设置分页的size
       */
      handleSizeChange(val) {
        this.pager.size = val;
        this.pager.page = 1;
        this.queryDevices();
      },

      /**
       * 获取所有的设备
       */
      async queryDevices() {
        this.listLoading = true;
        let searchParam = Object.assign({}, this.filters);
        if (
          Object.prototype.toString.call(searchParam.isProxy) !=
          "[object Boolean]"
        ) {
          delete searchParam.isProxy;
        }
        const responseData = await searchDevicesByType(searchParam, this.pager);
        if (responseData && responseData.data && responseData.errorCode === 0) {
          this.dvcTypeList = responseData.data.list || [];
          this.total = responseData.data.total;
        }
        this.loaded = true;
        this.listLoading = false;
      },
    },
    async created() {
      this.filters.key = tempKey;
      if (this.$route.query.page) {
        this.pager.page = +this.$route.query.page;
      }
      this.queryDevices();
    },
  };
</script>
