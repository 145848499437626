<style lang="less" scoped>
  .deviceType-edit {
    .form-foot {
      text-align: right;
    }
  }
</style>

<template>
  <div class="deviceType-edit">
    <el-form
      ref="editForm"
      label-position="left"
      :model="formData"
      :rules="rules">
      <el-form-item label="型号名称" prop="name">
        <el-input v-model="formData.name"></el-input>
      </el-form-item>

      <el-form-item label="系列编码" prop="deviceTypeCode">
        <el-input v-model="formData.deviceTypeCode"></el-input>
      </el-form-item>

      <el-form-item label="产品标识" prop="productTypeCode">
        <el-input v-model="formData.productTypeCode"></el-input>
      </el-form-item>

      <el-form-item label="备注信息" prop="remarks">
        <el-input type="textarea" :rows="4" v-model="formData.remarks"></el-input>
      </el-form-item>
    </el-form>

    <div class="form-foot">
      <el-button
        @click="$emit('cancel')">
        取消
      </el-button>

      <el-button
        type="primary"
        @click="handleSubmit">
        确认
      </el-button>
    </div>
  </div>
</template>

<script>
import {updateDvcType, createDvcType} from 'src/services/device';

export default {
  data () {
    return {
      formData: {
        id: '',
        name: '',
        images: '',
        deviceTypeCode: '',
        productTypeCode: '',
        remarks: ''
      },
      rules: {
        name: [{required: true, message: '请输入', trigger: 'blur'}],
        deviceTypeCode: [{required: true, message: '请输入', trigger: 'blur'}],
        productTypeCode: [{required: true, message: '请输入', trigger: 'blur'}],
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    propRowData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    // 初始化表单
    initForm () {
      if (this.propRowData && this.propRowData.id) {
        console.log(this.propRowData)
        for(let key in this.formData) {
          this.$set(this.formData, key, this.propRowData[key]);
        }
      }
    },

    // 提交表单
    handleSubmit () {
      this.$refs.editForm.validate(async valid => {
        if (valid) {
          let resData = null
          if (this.formData.id) {
            resData = await updateDvcType(this.formData)
          } else {
            delete this.formData.id
            resData = await createDvcType(this.formData)
          }
          if (resData.errorCode === 0 && resData.data) {
            this.$notify.success({
              title: '成功',
              message: `${this.formData.id?'修改':'新增'}成功`
            });
            this.$emit('submit', resData.data);
          }
        }
      });
    },
  },
  watch: {
    visible: {
      handler (newVal) {
        if (newVal) {
          this.initForm()
        } else {
          this.formData = {
            id: '',
            name: '',
            images: '',
            deviceTypeCode: '',
            productTypeCode: '',
            remarks: ''
          }
          // 移除表单数据和验证规则
          this.$refs.editForm.resetFields();
        }
      },
      immediate: true
    }
  }
}
</script>
